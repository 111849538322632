.loginCard {
    margin-top: 2.5rem !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    width: 25.625rem;
    height: 25.688rem;
    min-height: 390px !important;
    padding: 1.5rem;
    overflow: unset;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.helpText {
    text-align: center;
    font-family: "Roboto-Medium";
}

.cardHeading {
    margin-top: 1rem !important;
    margin-bottom: 1.5rem !important;
    color: black;
    text-align: center;
}

.textField {
    height: 3.438rem !important;
}

.btnInCard {
    color: #0965df !important;
    font-family: "Roboto-Medium" !important;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.btnIniciarSesion {
    height: 2.625rem;
    min-height: 42px;
}