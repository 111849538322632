.content {
    width: 100%;
    height: 100%;
    //min-height: 100vh;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    color: rgb(255, 255, 255);
    bottom: 0;
    overflow-y: auto !important;
}

.section {
    height: 100%;
    background-size: cover;
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.logo {
    margin-top: 6rem;
    width: 21.386rem;
    height: 3.313rem;
}

.appName {
    margin-top: 2rem !important;
}

.appEdition {
    margin-top: 0.5rem !important;
    font-family: "Roboto-Medium" !important;
}

.tienesCuenta {
    margin-top: 2.5rem !important;
    font-family: "Roboto-Medium" !important;
}

.btnCrearCuenta {
    margin-top: 1rem !important;
    width: 25.625rem;
    height: 2.625rem;
    font-family: "Roboto-Medium" !important;
    font-size: 0.938rem !important;
}

.footer {
    margin-top: 3.125rem !important;
    margin-bottom: 1.5rem !important;
}


.btnScheduler {
    background-color: #0965df !important;
    height: 2.625rem;
}
  
  .btnScheduler:disabled {
    background-color: #cccccc !important;
}
  
  .btnCrearCuenta {
    background-color: #2196f3 !important;
}