.MuiCalendarPicker-root {
    height: 350px !important;
    border-radius: 4px;
}

.MuiCalendarPicker-viewTransitionContainer {
    overflow-y: hidden !important;
}

.PrivatePickersSlideTransition-root {
}

.calendarborder {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    width: 375px;
}



hr {
    color: black;
}

.MuiFormGroup-root {
    width: 100%;
}

.MuiFormControlLabel-root {
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: space-between !important;
    margin-left: 0.875rem !important;
    margin-right: 0.875rem !important;
}

.scrollable {
    max-height: 350px;
    overflow-y: scroll;
}

.scrollable::-webkit-scrollbar {
    width: 5px;
}

.scrollable::-webkit-scrollbar-track {    
    background: #f5f5f5;
}

.scrollable::-webkit-scrollbar-thumb {
    border-radius: 5px; 
    background: #bdbdbd;
}

.scrollable::-webkit-scrollbar-thumb:hover {    
    background: #adadad;
}