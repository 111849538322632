root {
    display: 'flex';
    zIndex: 0;
}
.sideBar {
    background-color: white;
}
.drawer {
    width: 20%;
    flexShrink: 0;
    zIndex: 0;
}
.drawerContainer {
    overflow: auto;
}